<template>
    <div>
        <div class="background-img pa-5 mb-5">
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-row justify="center">
                        <v-col cols="8" sm="8" md="6">
                            <v-img src="../../public/asahi_PRGR_g.png" contain class="mx-auto"></v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" sm="8" md="6">
                            <v-img src="../../public/kyosan_g.png" contain class="mx-auto mt-2"></v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-col cols="10" sm="8" md="6">
                            <div class="text-center mb-4"><a href="https://prgr-ginza.com/" class="link">https://prgr-ginza.com/</a></div>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-col cols="10" sm="8" md="6">
                            <v-img src="../../public/open_golf_compe_g.png" contain class="my-2 mx-auto"></v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12">
                            <v-img src="../../public/asahi_online_cup2_g.png" class="mx-auto"></v-img>
                        </v-col>
                    </v-row>
                    <v-row dense class="my-3">
                        <v-col cols="auto">
                            <div class="text-h5 font-weight-black end-word">【開催終了】ご参加ありがとうございました</div>
                        </v-col>
                        <!--<v-col cols="auto">-->
                        <!--    <v-btn rounded v-on:click="goSignUp()" color="secondary" class="btn-size" height="40">-->
                        <!--        <v-row no-gutters>-->
                        <!--            <v-col cols="1"><v-icon small>mdi-arrow-right-drop-circle-outline</v-icon></v-col>-->
                        <!--            <v-col cols="11"><span>初めての方はコチラ</span></v-col>-->
                        <!--        </v-row>-->
                        <!--    </v-btn>-->
                        <!--</v-col>-->
                        <!--<v-col cols="auto">-->
                        <!--    <v-btn rounded v-on:click="goSignIn()" color="secondary" class="btn-size" height="40">-->
                        <!--        <v-row no-gutters>-->
                        <!--            <v-col cols="1"><v-icon small>mdi-arrow-right-drop-circle-outline</v-icon></v-col>-->
                        <!--            <v-col cols="10"><span>ログイン</span></v-col>-->
                        <!--        </v-row>-->
                        <!--    </v-btn>-->
                        <!--</v-col>-->
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-card tile flat class="light-green lighten-5">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                            <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                <v-icon color="light-green darken-3" class="mr-5">mdi-gift</v-icon>
                                豪華賞品
                            </v-card-title>
                            <v-card-text class="font-color">
                                <v-container>
                                    <v-row dense>
                                        <v-col cols="6">
                                            <v-img src="../../public/best10_2023_4.jpg" height="100%"></v-img>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-img src="../../public/ladys_2023_4.jpg"></v-img>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="4">
                                            <v-img src="../../public/PRGRkyousan_2023_4.jpg"></v-img>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-img src="../../public/asahiseimei_2023_4.jpg"></v-img>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-img src="../../public/team_2023_4.jpg" height="100%"></v-img>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-row dense> 
                                    <v-col>
                                        <span>他にも賞品ございます！(賞品一覧参照)</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <br>
                    <div>
                        <p class="text-h6">大会概要</p>
                        <v-card>
                            <v-responsive :aspect-ratio="16/9">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/l44WYCppwcI?rel=0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </v-responsive>
                        </v-card>
                    </div>
                    
                    <v-card tile flat class="light-green lighten-5 card-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                    <v-icon color="light-green darken-3" class="mr-5">mdi-calendar-month</v-icon>
                                    コンペ期間
                                </v-card-title>
                                <v-card-text class="font-color">
                                    2023年4月1日(土)～2023年5月31日(水)<br>
                                    ※スコア提出は5月31日(水)23時59分まで
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <v-card tile flat class="light-green lighten-5 card-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                    <v-icon color="light-green darken-3" class="mr-5">mdi-check-outline</v-icon>
                                    ルール
                                </v-card-title>
                                <v-card-text class="font-color">
                                    <ol>
                                        <li>ゴルフコースの指定はありません<br/>
                                            <div class="indent">※18ホール、パー72のゴルフコースを対象とします</div> 
                                        </li>
                                        <li>競技形式はグロスとなります</li>
                                        <li>同グロスの場合、生年月日の早い方が上位となります<br/>
                                            <div class="indent">※チーム戦で平均スコア同一の場合の順位は、チーム登録の早い順とします<br/>
                                            チーム登録は、３名以上登録され、チームのスコアが表示された時点での判定となります</div> 
                                        </li>
                                        <li>ゴルフ場のローカルルールに従うこと</li>
                                        <li>参加登録とスコアの提出をもって、参加完了となります</li>
                                        <li>複数回登録された場合は、ベストスコアがランキングに掲載されます</li>
                                    </ol>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <!--<v-card tile flat class="light-green lighten-5 card-margin">-->
                    <!--    <v-row justify="center">-->
                    <!--        <v-col cols="12" md="10" >-->
                    <!--            <v-card-title class="light-green--text text--darken-3 text-h5 my-2">-->
                    <!--                <v-icon color="light-green darken-3" class="mr-5">mdi-account-heart-outline</v-icon>-->
                    <!--                参加者登録について-->
                    <!--            </v-card-title>-->
                    <!--            <v-card-text class="font-color">-->
                    <!--                <ol>-->
                    <!--                    <li>-->
                    <!--                        <v-btn rounded small v-on:click="goSignUp()" color="secondary">-->
                    <!--                            <v-icon small left>mdi-arrow-right-drop-circle-outline</v-icon>-->
                    <!--                            初めての方はコチラ-->
                    <!--                        </v-btn>-->
                    <!--                        をクリック-->
                    <!--                    </li>-->
                    <!--                    <li>必要情報を入力（3分程度）</li>-->
                    <!--                    <li>登録完了</li>-->
                    <!--                    <li>マイページ画面より、ゴルフ仲間をご招待いただけます。ぜひ皆様でご参加ください！！</li>-->
                    <!--                </ol>-->
                    <!--            </v-card-text>-->
                    <!--        </v-col>-->
                    <!--    </v-row>-->
                    <!--</v-card>-->
                    
                    <v-card tile flat class="light-green lighten-5 card-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                    <v-icon color="light-green darken-3" class="mr-5">mdi-application-edit-outline</v-icon>
                                    スコア提出について
                                </v-card-title>
                                <v-card-text class="font-color">
                                    <ol>
                                        <li>マイページのスコア登録画面より提出いただけます</li>
                                        <li>
                                            提出時には、スコアの入力とスコアカードの画像(※)をご提出いただきます<br>
                                            <div class="indent">※印刷スコア、スマートフォンのスクリーンショットを含みます</div>
                                        </li>
                                        <li>スコア提出は5月31日(水)23時59分までとなります</li>
                                    </ol>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <v-card tile flat class="light-green lighten-5 card-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                    <v-row no-gutters>
                                        <v-col cols="auto"><v-icon color="light-green darken-3" class="mr-5">mdi-podium</v-icon></v-col>
                                        <v-col>ランキングの確認について</v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="font-color">
                                    <ol>
                                        <li>マイページのランキング画面よりご確認いただけます</li>
                                        <li>ご登録いただいたスコアの中で、一番良いスコアがランキングに反映されます</li>
                                        <li>ランキングはニックネーム、チーム名で表示されます</li>
                                    </ol>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                    <div class="btn mb-3 card-margin">
                        <!--<v-btn rounded large v-on:click="goSignUp()" color="secondary" class="btn-size">-->
                        <!--    <v-icon left>mdi-arrow-right-drop-circle-outline</v-icon>-->
                        <!--    初めての方はコチラ-->
                        <!--</v-btn>-->
                    </div>
                    
                    <v-card tile flat class="light-green lighten-5 card-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="light-green--text text--darken-3 text-h5 my-2">
                                    <v-icon color="light-green darken-3" class="mr-5">mdi-golf</v-icon>
                                    賞品一覧
                                </v-card-title>
                                <v-card-text class="font-color">
                                    <v-row dense>
                                        <ul>
                                            <li class="mb-2"><b>ベスト10賞（グロススコア順位1～10位）</b><br><span class="text-subtitle-1">キャディバッグ</span></li>
                                            <li class="mb-2"><b>レディース賞（女性参加者 グロススコア順位1～5位）</b><br><span class="text-subtitle-1">ボストンバッグ</span></li>
                                            <li class="mb-2"><b>PRGR賞（順位下2桁が40の方）</b><br><span class="text-subtitle-1">クーラーバッグ</span></li>
                                            <li class="mb-2"><b>朝日生命賞（順位下2桁が35の方）</b><br><span class="text-subtitle-1"> サンバイザー</span></li>
                                            <li class="mb-2"><b>チーム賞（順位下1～5位のチーム全員）</b><br><span class="text-subtitle-1">ウッドティ&マーカーセット</span></li>
                                            <li class="mb-2"><b>ラッキー5・7賞（順位下1桁が5,7の方）</b><br><span class="text-subtitle-1">蛍光マーカー</span></li>
                                            <li class="mb-2"><b>参加賞</b><br><span class="text-subtitle-1">ゴルフボール3個</span></li>
                                            <li class="mb-2"><b>紹介賞（紹介5名ごと）</b><br><span class="text-subtitle-1">PRGRギフトセット</span></li>
                                        </ul>
                                    </v-row>
                                    <div class="indent">※賞品は、ご登録いただいたメール、電話にご連絡のうえ、弊社担当者よりお届けいたします</div>
                                    <div class="indent">※チーム賞、参加賞、紹介賞以外の賞品は個人スコア上位1,500名様までを対象とします。</div>
                                    <div class="indent">※同一期間内に当社の複数のプレゼントに当選された場合、景品表示法の規制によりいずれかを選択いただくこともありますので、ご了承ください</div>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card tile flat class="grey lighten-3 card-margin bottom-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="grey--text text-h6 text--darken-3">
                                    <v-icon color="grey darken-3" class="mr-5">mdi-lock-open-outline</v-icon>
                                    クッキー（cookie）ポリシー
                                </v-card-title>
                                <v-card-text class="font-color">
                                    当サイトの使用するクッキー（cookie）ポリシーについては、
                                    <a href="https://www.asahi-life.co.jp/company/read/cookie_policy.html">コチラ</a>
                                    をご確認ください。
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-card tile flat class="grey lighten-3 card-margin bottom-margin">
                        <v-row justify="center">
                            <v-col cols="12" md="10" >
                                <v-card-title class="grey--text text--darken-3 text-h5 my-2">
                                    <v-icon color="grey darken-3" class="mr-5">mdi-email</v-icon>
                                    お問い合わせ先
                                </v-card-title>
                                <v-card-text class="font-color">
                                    Asahi Online Cup事務局<br>
                                    mail : Webcampaign_tantou.pa@mail.asahi-life.co.jp
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
   export default{
        data: () => ({
            nickname: '',
        }),
        methods: {
            goSignUp: function(){
                this.$router.push('/golf/signup');
            },
            goSignIn: function(){
                this.$router.push('/golf/signin');
            },
        },
        created: function() {
                if(this.$route.query.staffCode != undefined){
                    this.$store.commit('setStaffCode', this.$route.query.staffCode);
                }
                if(this.$route.query.venueCd != undefined){
                    this.$store.commit('setVenueCd', this.$route.query.venueCd);
                }
                if(this.$route.query.invuserId != undefined){
                    this.$store.commit('setInvuserId', this.$route.query.invuserId);
                }
            }
    };
</script>

<style scoped>
    .card-margin{
        margin-top: 30px;
    }
    .bottom-margin{
        margin-bottom: 30px;
    }
    .background-img{
        background-color: #8FD016;
    }
    .link{
        color: #2b3722;
    }
    .indent{
        padding-left: 0.5em;
        text-indent: -0.5em;
    }
</style>
