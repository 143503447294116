import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.lightGreen.darken3,
                secondary: '#2b3722',
                info: colors.lightGreen.darken4,
            }
        }
    }
});
