<template>
    <div v-if="$route.meta.headerBtn">
        <v-app-bar app color="primary" elevate-on-scroll>
            <v-toolbar-title>
                <v-btn text color="white" class="change-logo-style" v-on:click="goMypage()">
                    Asahi Online Cup
                </v-btn>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row dense justify="end">
                <v-col v-if="$route.meta.onMypage"></v-col>
                <v-col v-else cols="auto" sm="2" class="text-right">
                    <v-btn text color="white" v-on:click="goMypage()" width="60">
                        <v-row no-gutters>
                            <v-col cols="12"><v-icon dense>mdi-home</v-icon></v-col>
                            <v-col cols="12" class="btn-text">マイページ</v-col>
                        </v-row>
                    </v-btn>
                </v-col>
                <v-col cols="auto" sm="2" class="text-right">
                    <v-btn text color="white" v-on:click="signOutAndGoSigninPage()" width="60">
                        <v-row no-gutters>
                            <v-col cols="12"><v-icon dense>mdi-logout</v-icon></v-col>
                            <v-col cols="12" class="btn-text">ログアウト</v-col>
                        </v-row>
                    </v-btn>
                </v-col>
            </v-row>
            <!--<div>-->
            <!--    <div v-if="$route.meta.onMypage"></div>-->
            <!--    <div v-else>-->
                    <!--<v-btn text small color="white" v-on:click="goMypage">-->
                    <!--    <v-icon class="mr-1" small>-->
                    <!--        mdi-home-->
                    <!--    </v-icon>-->
                    <!--    マイページ-->
                    <!--</v-btn>-->
            <!--    </div>-->
            <!--    <v-btn text small color="white" v-on:click="signOutAndGoSigninPage" id="btn-signout">-->
            <!--        <v-icon class="mr-1" small>-->
            <!--            mdi-logout-->
            <!--        </v-icon>-->
            <!--        ログアウト-->
            <!--    </v-btn>-->
            <!--</div>-->
        </v-app-bar>
    </div>
    <div v-else>
        <v-app-bar app color="primary" elevate-on-scroll>
            <v-toolbar-title>
                <span class="text-button white--text change-logo-style">Asahi Online Cup</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="$route.meta.onMypage">
                <v-btn text color="white" v-on:click="signOutAndGoSigninPage()" width="60">
                    <v-row no-gutters>
                        <v-col cols="12"><v-icon dense>mdi-logout</v-icon></v-col>
                        <v-col cols="12" class="btn-text">ログアウト</v-col>
                    </v-row>
                </v-btn>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
    export default {
        methods: {
            signOutAndGoSigninPage: async function() {
                var signoutObj = await this.signOut();
                // 自動ログアウトの場合、メッセージコンポーネントをログイン画面に表示する
                if(signoutObj.signinSessionExpirationOccurred){
                    const msg = '一定時間経過したため、自動ログアウトしました。再度ログインをお願いします。';
                    this.dispNotifMessageAfterPageTransition(msg, 'warning', '/golf/signin'); 
                }
                this.$router.push(signoutObj.nextPath);
            },
            goMypage: function() {
                this.$router.push('/golf/mypage');
            }
        }
    };
</script>

<style scoped>
    .change-logo-style {
        text-transform: none !important;
        font-size: 16px !important;
        padding: 0 !important;
    }
    .btn-text{
        font-size: 8px;
    }
</style>