var aws_cognito = require('amazon-cognito-identity-js');
var uuidModule = require('uuid');

//export default class cognito {
module.exports = class cognito {
  user_pool = null;
  currentUser = null;
  idToken = null;
  idTokenExp = null;
  autoSignined = null;
  
  //////////////////////////////////////////////////
  // ユーザープールobject作成
  //////////////////////////////////////////////////
  initUserPool(userPoolId, clientId){
      this.user_pool = new aws_cognito.CognitoUserPool({
          UserPoolId : userPoolId,
          ClientId : clientId,
      });
  }
  
  //////////////////////////////////////////////////
  // サインアップ
  //////////////////////////////////////////////////
  signUp (smsVerification, telnumber, email, password) {
    //UUIDv4を生成
    const newUuid = uuidModule.v4();
    // cognitoサーバーに携帯電話番号を登録するために国際電話番号に変換
    const internationalTelNumber = this.convertToInternationalTelNumber(telnumber);
    
    var nameAttr;
    var phoneAttr;
    var emailAttr;
    var customPhoneAttr;
    var customEmailAttr;
    //var preferredUserNameAttr;
    //var customUserIdAttr;
    if(smsVerification){
      //var username = internationalTelNumber;
      nameAttr = { Name: 'name', Value: newUuid };
      phoneAttr = { Name: 'phone_number', Value: internationalTelNumber };
      emailAttr = { Name: 'email', Value: '' };
      customPhoneAttr = { Name: 'custom:custom_phone_number', Value: telnumber };
      customEmailAttr = { Name: 'custom:custom_email', Value: email };
      //preferredUserNameAttr = { Name: 'preferred_username', Value: newUuid };
      //customUserIdAttr = { Name: 'custom:user_id', Value: newUuid };
    }else{
      //var username = email;
      nameAttr = { Name: 'name', Value: newUuid };
      phoneAttr = { Name: 'phone_number', Value: '' };
      emailAttr = { Name: 'email', Value: email };
      customPhoneAttr = { Name: 'custom:custom_phone_number', Value: telnumber };
      customEmailAttr = { Name: 'custom:custom_email', Value: email };
      //customUserIdAttr = { Name: 'custom:user_id', Value: newUuid };
      //preferredUserNameAttr = { Name: 'preferred_username', Value: newUuid };
    }
    
    const attributeList = [];
    attributeList.push(new aws_cognito.CognitoUserAttribute(nameAttr));
    attributeList.push(new aws_cognito.CognitoUserAttribute(phoneAttr));
    attributeList.push(new aws_cognito.CognitoUserAttribute(emailAttr));
    attributeList.push(new aws_cognito.CognitoUserAttribute(customPhoneAttr));
    attributeList.push(new aws_cognito.CognitoUserAttribute(customEmailAttr));
    //attributeList.push(new aws_cognito.CognitoUserAttribute(customUserIdAttr));
    //attributeList.push(new aws_cognito.CognitoUserAttribute(preferredUserNameAttr));

    return new Promise((resolve, reject) => {
      //this.user_pool.signUp(username, password, attributeList, null, (err, result) => {
      this.user_pool.signUp(newUuid, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve({ result: result, newUuid: newUuid, });
        }
      });
    });
  }
  
  forgetPassword(username){
    const userData = { Username: username, Pool: this.user_pool };
    const cognitoUser = new aws_cognito.CognitoUser(userData);
    return new Promise((resolve,reject)=>{
      cognitoUser.forgotPassword({
        onSuccess: result=>{
          console.log('Info:', result);
          resolve(result);
        },
        onFailure: err=>{
          console.log('Error:', err);
          reject(err);
        },
      });
    });
  }
  resetPassword(username, newPassword, code){
    const userData = { Username: username, Pool: this.user_pool };
    const cognitoUser = new aws_cognito.CognitoUser(userData);
    return new Promise((resolve,reject)=>{
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: result=>{
          console.log('Info:', result);
          resolve(result);
        },
        onFailure: err=>{
          console.log('Error:', err);
          reject(err);
        },
      });
    });
  }
  updatePassword(oldPass, newPass){
    this.currentUser.changePassword(oldPass,newPass,(err, result) => {
        if(err){
          console.log('Error:', err);
          return;
        }
        console.log('Info:', result);
    });
  }
  updateAttribute(attrName, attrValue){
    var attrObj = {
      Name: attrName,
      Value: attrValue,
    };
    var attr = new aws_cognito.CognitoUserAttribute(attrObj);
    var attrList = [];
    attrList.push(attr);
    
    return new Promise((resolve, reject)=>{
      var session;
      if(this.autoSignined){
        session = this.autoSignIn();
      }else{
        session = this.isAuthenticated();
      }
      session.then(session=>{
           if (!session.isValid()) {
            reject(null);
          } else {
            this.currentUser.updateAttributes(attrList, (err,result)=>{
              if(err){
                reject(err);
              } else {
                resolve(result);
              }
            });
          }
      })
      .catch(err=>{
          reject(err);
      });
    });
  }
  
  //////////////////////////////////////////////////
  // サインイン
  //////////////////////////////////////////////////
  signIn (telnumber, email, preferredUsername, password, uuid) {
    // cognitoサーバーに携帯電話番号を登録するために国際電話番号に変換
    
    var username = "";
    if(telnumber !== "") {
      const internationalTelNumber = this.convertToInternationalTelNumber(telnumber);
      username = internationalTelNumber;
    } else if (email !== "") {
      username = email;
    } else {
      username = preferredUsername;
    }
    
    const userData = { Username: username, Pool: this.user_pool };
    const cognitoUser = new aws_cognito.CognitoUser(userData);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new aws_cognito.AuthenticationDetails(authenticationData);
    
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.idToken = result.getIdToken().getJwtToken();
          this.idTokenExp = result.getIdToken().payload.exp;
          if(!uuid){
            try{
              localStorage.setItem('rt', result.refreshToken.token);
              localStorage.setItem('un', uuid);
            // REPLで実行するとlocalStorageというビルトイン変数がないためそのReferenceErrorを抑制
            }catch(e){ if(!(e instanceof ReferenceError)) throw e; }
          }
          
          this.autoSignined = false;
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }
  
  //////////////////////////////////////////////////
  // オーソライゼージョン
  //////////////////////////////////////////////////
  isAuthenticated () {
    this.currentUser = this.user_pool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) { reject(this.currentUser) }
      this.currentUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            this.idToken = session.idToken.jwtToken;
            this.idTokenExp = session.idToken.payload.exp;
            //this.accessToken = session.idToken.jwtToken;
            resolve(session);
          }
        }
      });
    });
  }
  
  //////////////////////////////////////////////////
  // 自動サインイン
  //   手動ログイン、すなわちsignIn()の後は、
  //   isAuthenticated()でcurrentUserを取得し、sessionを生成する。
  //   refreshトークンとユーザ名がlocalStorageにある場合は
  //   この関数で自動ログインでき、currentUserを取得でき、session生成もできる。
  //////////////////////////////////////////////////
  autoSignIn () {
      var username        = localStorage.getItem('un');
      var refreshTokenStr = localStorage.getItem('rt');
      return this.signOut(username, refreshTokenStr);
  }
  refreshSession (username, refreshTokenStr ) {
    this.currentUser = new aws_cognito.CognitoUser({
      Username: username,
      Pool: this.user_pool,
    });
    
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) { reject(this.currentUser) }
      
      var refreshToken = new aws_cognito.CognitoRefreshToken({RefreshToken:refreshTokenStr});
      this.currentUser.refreshSession(refreshToken, (err,session)=>{
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            this.idToken = session.idToken.jwtToken;
            this.idTokenExp = session.idToken.payload.exp;
            //this.accessToken = session.idToken.jwtToken;
            this.autoSignined = true;
            resolve(session);
          }
        }
      });
    });
  }

  //////////////////////////////////////////////////
  // サインアウト
  //////////////////////////////////////////////////
  signOut () {
    if (this.user_pool.getCurrentUser()) {
      // signOut()には戻り値がない。
      this.user_pool.getCurrentUser().signOut();
      try{
        localStorage.removeItem('rt');
        localStorage.removeItem('un');
      // REPLで実行するとlocalStorageというビルトイン変数がないためそのReferenceErrorを抑制
      }catch(e){ if(!(e instanceof ReferenceError)) throw e; }
      return true;
    }else{
      return false;
    }
  }
  
  //////////////////////////////////////////////////
  // globalサインアウト
  // - サインアウトだけでなく、refreshTokenをrevoke(無効化)する。
  // - signIn()だけでなく、getSession()したあとでないと「Error: User is not authenticated」になる
  //   this.currentUser = this.user_pool.getCurrentUser();したあとに
  //   this.currentUser.getSession()したcurrentUserじゃないといけない。
  //   新たにgetCurrentUser()すると、「Error: User is not authenticated」となる
  //////////////////////////////////////////////////
  globalSignOut () {
    this.currentUser = this.user_pool.getCurrentUser();
    return new Promise((resolve,reject)=>{
      var session;
      if(this.autoSignined){
        session = this.autoSignIn();
      }else{
        session = this.isAuthenticated();
      }
      session.then(()=>{
        if (this.currentUser) {
          try{
            localStorage.removeItem('rt');
            localStorage.removeItem('un');
          // REPLで実行するとlocalStorageというビルトイン変数がないためそのReferenceErrorを抑制
          }catch(e){ if(!(e instanceof ReferenceError)) throw e; }
          this.currentUser.globalSignOut({
            onSuccess: result=> resolve(result),
            onFailure: error => reject(error),
          });
        }else{
          return reject(false);
        }
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  //////////////////////////////////////////////////
  // ユーザーアカウントの属性取得
  //////////////////////////////////////////////////
  getAttributes () {
    this.currentUser = this.user_pool.getCurrentUser();
    
    return new Promise((resolve, reject) => {
      var session;
      if(this.autoSignined){
        session = this.autoSignIn();
      }else{
        session = this.isAuthenticated();
      }
      session.then(()=>{
        this.currentUser.getUserAttributes((err, attrs) => {
          if (err) {
            reject(err);
          }
          else {
            resolve(attrs);
          }
        });
      })
      .catch(err => {
        reject(err);
      });
    });
  }
  
  extractAttrByName(attrs, attrName){
      var attrObjArr = attrs.filter(attrObj=> attrObj.Name === attrName);
      if(attrObjArr){
          return attrObjArr[0].Value;
      }else{
          return null;
      }
  }
  
  //////////////////////////////////////////////////
  // 認証コード検証（サインアップ時）
  //////////////////////////////////////////////////
  //confirmRegistration()の第一引数はusernameでなくてはならない。
  //emailなどがusernameの場合はemailになり、nameがusernameの場合はnameとなる。
  confirmation (username, confirmationCode) {
    const userData = { Username: username, Pool: this.user_pool };
    const cognitoUser = new aws_cognito.CognitoUser(userData);
    
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if(err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }
  
  // コード認証してなくても動くらしい
  resend (telnumber) {
    // cognitoサーバーに携帯電話番号を登録するために国際電話番号に変換
    const internationalTelNumber = this.convertToInternationalTelNumber(telnumber);
    
    const userData = { Username: internationalTelNumber, Pool: this.user_pool };
    const cognitoUser = new aws_cognito.CognitoUser(userData);
    
    cognitoUser.resendConfirmationCode((err,result)=>{
      if(err){
        return;
      }
      return result;
    });
  }
  // test: change password
  //       コード認証済みであること
  //cognitoUser.changePassword('oldpass', 'newpass', function(err,result){;});
  
  // test: forgot password
  //       コード認証してなくてもよいらしい
  //cognitoUser.forgotPassword(...)がある
  
  // test: change password
  //cognitoUser.changePassword
  
  // コード認証済みであること
  deleteUser(){
    return new Promise((resolve, reject)=>{
      if(this.currentUser === null){ reject(this.currentUser); }
      
      this.currentUser.deleteUser((err,result)=>{
        if(err){
          reject(err);
        }
        resolve(result);
      });
    });
  }
  
  //////////////////////////////////////////////////
  // 電話番号変換
  // ※cognitoにおける電話番号は国際電話番号形式である必要がある
  //////////////////////////////////////////////////
  convertToInternationalTelNumber(telnumber) {
    return '+81' + telnumber.slice(1);
  }
};

