import Vue from 'vue';
import VueRouter from 'vue-router';
import landingPage from '../views/landingPage.vue';

Vue.use(VueRouter);

var router = new VueRouter({
  routes:[
    {
      path: '*',
      redirect: '/golf',
    },
    {
      path: '/golf',
      component: landingPage,
      meta: {
        onLp: true,
      }
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0};
  }
});

export default router;
