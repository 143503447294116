import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    // ストレージのキーを指定。デフォルトはvuex
    key: "golfVuex",
    //ストレージの種類を指定する。デフォルトはローカルストレージ
    storage: window.sessionStorage,
    paths: ['userData', 'config']
  })],
  state: {
    //userId: null,
    userData: {
      uuid: null, //userId取得APIとの連携を始めるころは、この初期値はnullにすること。
      telNumber: null,
      mailAddress: null,
      password: null,
      staffCode: null,
      registState: null,
      compeRegistState: null,
      venueCd: null,
      invuserId: null,
      teamData: {
        name: null,
        id: null
      }
    },
    scoreRegist: {
      score: undefined,
      photoBase64: undefined,
    },
    config: {
      // AWS Cognitoで作ったユーザープールのプールID,
      COGNITO_ID: 'ap-northeast-1_xLEItF5aR',
      // AWS Cognitoで作ったアプリクライアントID
      COGNITO_CLIENT_ID: '474f3j88750sq8r5osagibds8l',
    },
    errorMsg: null,
    notifMsg: null,
    paramObj: null,
    teamId: null,
    teamName: null,
    companyData: {
      name: null,
      id: null
    },
    officeData: [],
  },
  mutations: {
    deleteAllUserData(state) {
      state.userData = {};
    },
    setUserData(state, payload) {
      state.userData.uuid = payload.uuid;
      state.userData.telNumber = payload.telNumber;
      state.userData.mailAddress = payload.mailAddress;
    },
    setTelNumber(state, payload) {
      state.userData.telNumber = payload;
    },
    setPassword(state, payload) {
      state.userData.password = payload;
    },
    setStaffCode(state, payload) {
      state.userData.staffCode = payload;
    },
    setRegistState(state, payload) {
      state.userData.registState = payload;
    },
    setCompeRegistState(state, payload) {
      state.userData.compeRegistState = payload;
    },
    setVenueCd(state, payload) {
      state.userData.venueCd = payload;
    },
    setInvuserId(state, payload) {
      state.userData.invuserId = payload;
    },
    setScore(state, payload) {
      state.scoreRegist.score = payload;
    },
    setPhotoBase64(state, payload) {
      state.scoreRegist.photoBase64 = payload;
    },
    clearParamObj(state) {
      state.paramObj = null;
    },
    setParamObj(state, payload) {
      state.paramObj = payload;
    },
    setErrorMsg(state, payload) {
      state.errorMsg = payload;
    },
    setNotifMsg(state, payload) {
      state.notifMsg = payload;
    },
    setJoinedState(state, payload) {
      state.joinedTeamState = payload;
    },
    setTeamId(state, payload) {
      state.teamId = payload;
    },
    setTeamName(state, payload) {
      state.teamName = payload;
    },
    clearAllParam(state) {
      state.paramObj = null;
      state.scoreRegist = {
        score: null,
        photoBase64: null,
      }
    },
    setTeamData(state, payload) {
      state.userData.teamData = payload;
    },
    setCompanyData(state, payload) {
      state.companyData.name = payload.name;
      state.companyData.id = payload.id;
    },
    setOfficeData(state, payload) {
      state.officeData.splice(0);
      payload.map(function(value) {
        state.officeData.push(value)
      })
    },

  },
  actions: {
    // userRegistrationApi(context, payload){
    //   const body = {
    //     nickname: payload,
    //   };
    //   console.log('myInfo' + body.tmpNickname);
    // },
    userIdRegistration(context, payload) {
      context.commit('setUserId', payload);
    },
    scoreRegistration(context, payload) {
      context.commit('setScore', payload);
    },

    // 課題
    //   - context.state.paramObj なのかcontext.paramObjなのかどっち？
    extractParam(context) {
      var paramObj = context.state.paramObj;
      context.commit('clearParamObj');
      return paramObj;
    },

    // scoreRegistrationApi(context, payload){
    //   const body = {
    //     tmpScore: payload
    //   };
    //   console.log('myInfo' + body.tmpScore);
    //   // return api.post(   '', body);
    //   // context.commit("setScoreRegistration", payload);

    // }
  },
  modules: {}
});
