//1：本登録済み（ユーザ情報登録済み）2：仮登録済み（ユーザ情報登録未）
export const REGISTERED_IN_DYNAMODB = '1';
export const NOT_REGISTERED_IN_DYNAMODB = '2';

//  1：今期コンペ登録済 2：今期コンペ未登録
export const COMPE_REGISTERED = '1';
export const NOT_COMPE_REGISTERED = '2';

export const TABS = [{
        id: "Individually",
        type: "all",
        name: "個人",
        title: "全国個人ランキング",
        scrollId: "myRankIndividually",
        rankInfo: {
            yourRankMessage: "あなたの順位",
            scrollMessage: "自分のランキングを見る",
            unit: "人"
        },
    },
    {
        id: "Team",
        type: "team",
        name: "チーム",
        title: "全国チームランキング",
        scrollId: "myRankTeam",
        rankInfo: {
            yourRankMessage: "あなたの順位",
            scrollMessage: "自分のランキングを見る",
            unit: "チーム"
        },
    },
    {
        id: "Venue",
        type: "venue",
        name: "開催地",
        title: "カップ",
        scrollId: "myRankVenue",
        rankInfo: {
            yourRankMessage: "あなたの順位",
            scrollMessage: "自分のランキングを見る",
            unit: "人"
        },
    },
    {
        id: "Ladies",
        type: "lady",
        name: "ﾚﾃﾞｨｰｽ",
        title: "全国レディースランキング",
        scrollId: "myRankLadies",
        rankInfo: {
            yourRankMessage: "あなたの順位",
            scrollMessage: "自分のランキングを見る",
            unit: "人"
        },
    },
];

export const OUT_OF_COMPE_MESSAGE = '現在、コンペ開催期間ではないので当該機能は使用できません。';

// 該当なしの場合の法人コード（ゴルフアプリ内だけで設定しているもの）
export const NA_COMPANY_CODE = '0';

// 該当なしの場合の事業所コード（ゴルフアプリ内だけで設定しているもの）
export const NA_OFFICE_CODE = '0';
export const NA_OFFICE_CODE_USER_SELECTED = '99999';

export const NA_COMPANY_DATA = {
    name: "",
    id: NA_COMPANY_CODE,
};
export const NA_OFFICE_DATA = {
    name: "該当なし",
    id: NA_OFFICE_CODE_USER_SELECTED,
    office_name_reverso_context: "該当なし",
};

// 参加者登録画面 / ご職業リスト
export const JOBS = [{
        name: "会社員",
        validateType: "required"
    },
    {
        name: "役員",
        validateType: "required"
    },
    {
        name: "経営者",
        validateType: "required"
    },
    {
        name: "公務員",
        validateType: "required"
    },
    {
        name: "自営業",
        validateType: "optional"
    },
    {
        name: "主婦・主夫",
        validateType: "optional"
    },
    {
        name: "学生",
        validateType: "optional"
    },
    {
        name: "パート・アルバイト",
        validateType: "optional"
    },
    {
        name: "年金生活者・資産生活者",
        validateType: "optional"
    },
    {
        name: "無職",
        validateType: "optional"
    },
    {
        name: "その他",
        validateType: "optional"
    },
];

// 参加者登録画面 / フォームの表示文言
export const USER_REGISTRATION_INPUT_FORM_DISPLAY_TEXT = {
    company: {
        label: "お勤め先の会社名",
        placeholder: "（例）○○株式会社"
    },
    office: {
        label: "お勤め先の事業所名",
        placeholder: "（例）本社、○○支店、○○支社、○○営業所 など"
    },
    department: {
        label: "お勤め先の所属名（任意）",
        placeholder: "（例）○○部門、○○部、○○課、○○係 など"
    },
    office_department: {
        label: "お勤め先の事業所名・所属名",
        placeholder: "（例）本社 ○○部門、○○支店 ○○部 など"
    },
};

export const PREFECTURES = ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県", "群馬県", "埼玉県",
    "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "岐阜県", "静岡県",
    "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県", "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県",
    "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"
];

export const HAS_COMPANY_AND_OFFICE_DATA = "HAS_COMPANY_AND_OFFICE_DATA";
export const HAS_OFFICE_DATA_ONCLICK_NA = "HAS_OFFICE_DATA_ONCLICK_NA";
export const HAS_COMPANY_DATA_NA_OFFICE_DATA = "HAS_COMPANY_DATA_NA_OFFICE_DATA";
export const NA_COMPANY_AND_OFFICE_DATA = "NA_COMPANY_AND_OFFICE_DATA"
