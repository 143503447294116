const axios = require("axios");
const { REGISTERED_IN_DYNAMODB, NOT_REGISTERED_IN_DYNAMODB } = require("../utils/constants.js");
//import router from "../router/index.js";

var baseUrl = function() {
  if (process.env.VUE_APP_APIGW_BASE_URL) {
    return process.env.VUE_APP_APIGW_BASE_URL;
  }
  else if (process.env.VUE_APP_APIGW_BASE_PATH) {
    return 'https://' + window.location.hostname + process.env.VUE_APP_APIGW_BASE_PATH;
  }
}();
console.log('MYDEBUG: baseUrl:', baseUrl);

const myaxios = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    // "Access-Control-Allow-Origin": '*',
    // "Access-Control-Allow-Methods": 'POST, GET, DELETE, OPTIONS',
    //"Access-Control-Allow-Headers": 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
  }
});

myaxios.myapi = {};

// CONFIRMED状態な重複アカウントがあるかの確認
myaxios.myapi.confirmedUserExists = async function(phone, email) {
  const NO_CONFIRMED_ACCOUNT_EXISTS = 0;
  const CONFIRMED_ACCOUNT_EXISTS = 1;

  const response = await myaxios.get('/user_exists', {
    params: {
      tel_number: phone,
      mail_address: email,
    },
  });
  const status = response.data;

  if (status === CONFIRMED_ACCOUNT_EXISTS) {
    return true;
  }
  else if (status === NO_CONFIRMED_ACCOUNT_EXISTS) {
    return false;
  }
  else {
    throw 'INVALID_LAMBDA_RESPONSE';
  }
};

// DynamoDB内に個人情報登録済みかを確認
myaxios.myapi.personalDataExistsInDynamodb = async function(idToken) {

    const response = await myaxios.get('/get_registration_status', {
      headers: { "Authorization": idToken },
    });

    if (response.data.registration_status === REGISTERED_IN_DYNAMODB) {
      return true;
    }
    else if (response.data.registration_status === NOT_REGISTERED_IN_DYNAMODB) {
      return false;
    }
    else {
      throw 'INVALID_LAMBDA_RESPONSE';
    }
  },
  /*
  // 共通エラー処理
  myaxios.interceptors.response.use(
    function(response) {
      // 正常な場合は何も処理しない
      return response;
    },
    async function(error) {
      const isTimeout = error.code === "ECONNABORTED";

      if (isTimeout) {
        router.push('/error', 'Timeout');

        let message = { response: { data: { message: "timeout" } } };
        return Promise.reject(message);
      } else if (!error.response) {
        router.push('/error', 'Error');
        return Promise.reject(error);
      } else {
        let status;

        if (!error.response || !error.response.status) {
          status = 500;
        } else {
          status = error.response.status;
        }

        console.log("intercepter", status);

        if (status === 403) {
          // メンテナンス時(WAFから403が返ってくる)
          location.replace("/public/index.html");
        } else {
          const parsedError = await onBlobHandler(error);

          router.push('/error', parsedError.errorType);

          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

  //BlobをJSONに変換します。
  async function onBlobHandler(error) {
    if (error.request.responseType === "blob") {
      const text = await blobFileReader(error.response.data);
      return JSON.parse(text);
    } else {
      return error.response.data;
    }
  }

  const blobFileReader = blob => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject();
      };

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsText(blob);
    });
  };
  */

  module.exports = myaxios;
// export default myaxios;
