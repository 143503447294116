<template>
  <v-app>
    <div v-if="!$route.meta.onLp">
      <Header />
    </div>
    <v-main class="all-background">
      <div class="dev-background" id="dev-background">
        <div class="main-contents">
          <loading v-show="loading" />
          <errorComponent />
          <notifComponent />
          <router-view @isLoadingOn="loadingOn" @isLoadingOff="loadingOff" />
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
  import errorComponent from "./components/errorPanel";
  import notifComponent from "./components/notifPanel";
  import Header from "./components/Header";
  import Footer from "./components/Footer";
  import loading from "./components/loading";

  export default {
    name: 'App',
    data: () => ({
      loading: false,
    }),
    mounted() {
      if(!this.isDevSubDomain()) {
        const element = document.getElementById("dev-background")
        element.removeAttribute("class")
      }
    },
    methods: {
      loadingOn() {
        this.loading = true;
      },
      loadingOff() {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      },
      isDevSubDomain() {
        const subDomain = location.host.split('.')[0]
        if (subDomain != 'golfcompe') {
          return true;
        }
        return false;
      }
    },
    components: {
      errorComponent,
      notifComponent,
      Header,
      Footer,
      loading,
    },
  };
</script>

<style>
  .main-contents {
    height: 100%;
  }

  .btn {
    text-align: center;
  }

  .btn-size {
    width: 200px;
  }

  .all-background {
    background: #f9f9f9;
  }
  
  .dev-background {
    background-image: url('../public/background_dev-golfcompe.png');
    background-repeat: repeat;
  }

  .title-color {
    color: #3a5231;
  }

  .loading {
    margin-top: 50%;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
</style>
