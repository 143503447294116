<template>
    <div v-show="getMessageType">
        <v-container>
            <v-alert text :type="getMessageType" max-width="700" class="mx-auto">
                <p v-show="getMessage" :style="getTextColor">{{ getMessage }}</p>
            </v-alert>
        </v-container>
    </div>
</template>

<script>
    export default {
        computed: {
            getMessage: function() {
                if (this.$store.state.notifMsg) {
                    return this.$store.state.notifMsg.message;
                }
                return null;
            },
            getMessageType: function() {
                if (this.$store.state.notifMsg) {
                    return this.$store.state.notifMsg.type;
                }
                return null;
            },
            getDispPanel: function() {
                if (this.$store.state.notifMsg) {
                    return true;
                }
                return null;
            },
            getTextColor: function() {
                
                if ((this.$store.state.notifMsg ?? false) && this.$store.state.notifMsg.type == 'success') {
                    return {color:'black !important', nissy:this.$store.state.notifMsg.type}
                }
                return '';
            }
        },
    };
</script>
