<template>
    <div v-show="getDispPanel">
        <v-container>
            <v-alert text type="error" max-width="700" class="mx-auto">
                <p v-show="getErrInfoMessage">{{ getErrInfoMessage }}</p>
            </v-alert>
        </v-container>
    </div>
</template>

<script>
export default {
    computed: {
        getDispPanel: function() {
            if(this.$store.state.errorMsg){
                return  true;
            }
            return null;
        },
        getErrInfoMessage: function() {
            //if( this.propExists([()=>err, ()=>err.errorObj]) ){
            if( this.errorMsgExists() ){
                return this.$store.state.errorMsg.message;
            }
            return '';
        },
    },
};
</script>
