import { REGISTERED_IN_DYNAMODB, NOT_REGISTERED_IN_DYNAMODB, COMPE_REGISTERED, NOT_COMPE_REGISTERED } from "@/utils/constants.js";
export default {
    methods: {
        // エラーメッセージを表示する
        dispErrorMessage: function(msg, errObj) {
            // メッセージ表示位置すなわちページ上部にスクロールする
            scrollTo(0,0);
            
            var msgObj = {
                message: msg,
                errorObj: errObj,
            };
            // メッセージを表示する
            this.$store.commit('setErrorMsg', msgObj);
        },
        // 通知メッセージを表示する
        //   msgTypeで指定できるもの:
        //   - 'success'
        //   - 'warning'
        dispNotifMessage: function(msg, msgType) {
            // メッセージ表示位置すなわちページ上部にスクロールする
            scrollTo(0,0);
            
            var msgObj = {
                message: msg,
                type: msgType
            };
            // メッセージを表示する
            this.$store.commit('setNotifMsg', msgObj);
        },
        // ページ遷移後にエラーメッセージを表示する
        dispErrorMessageAfterPageTransition: async function(msg, err, targetPagePath) {
            // 0.5秒ごとに現在ページのパスを確認し、目的のページならループを終了する。
            // 60 x 0.5秒後はタイムアウトとし、ループを抜ける。タイムアウトならメッセージは表示しない。
            for(let e of this.generateArray(60)){
                e;// lintエラーを回避するためにこの行を記述しています。
                await this.sleep(500);
                if(this.$router.currentRoute.path === targetPagePath){
                    // ループを終了時、メッセージ表示を行う。
                    this.dispErrorMessage(msg,err);
                    break;
                }
            }
        },
        // ページ遷移後に通知メッセージを表示する
        dispNotifMessageAfterPageTransition: async function(msg, msgType, targetPagePath) {
            // 0.5秒ごとに現在ページのパスを確認し、目的のページならループを終了する。
            // 60 x 0.5秒後はタイムアウトとし、ループを抜ける。タイムアウトならメッセージは表示しない。
            for(let e of this.generateArray(60)){
                e;// lintエラーを回避するためにこの行を記述しています。
                await this.sleep(500);
                if(this.$router.currentRoute.path === targetPagePath){
                    // ループを終了時、メッセージ表示を行う。
                    this.dispNotifMessage(msg,msgType);
                    break;
                }
            }
        },
        // 指定時間の間だけ処理を停止する。
        sleep: async function(millisec){
            return new Promise(resolve=>setTimeout(()=>resolve(), millisec));
        },
        // 要素数を指定して配列を作成する。
        generateArray: function(numOfItem){
            return [...Array(numOfItem)];
        },
        // DynamoDBに個人情報を登録済みかどうかを確認する。
        isRegisteredInDynamodb: async function(){
            
            let regitrationStatus = {
                registState: null,
                compeRegistState: null
            };
            var userData = this.$store.state.userData;
            
            // すでに登録状況を取得済みならそれを返す
            if(userData.registState == REGISTERED_IN_DYNAMODB){
                regitrationStatus.registState = true;
                
                // 2回目の登録状況を取得済みならそれを返す
                if(userData.compeRegistState == COMPE_REGISTERED){
                    regitrationStatus.compeRegistState = true;
                }else if(userData.compeRegistState == NOT_COMPE_REGISTERED){
                    regitrationStatus.compeRegistState = false;
                }
                
                return regitrationStatus;
            }else if(userData.registState == NOT_REGISTERED_IN_DYNAMODB){
                regitrationStatus.registState = false;
                regitrationStatus.compeRegistState = false;
                
                return regitrationStatus;
            }
            
            // 登録状況を取得
            try{
                var response = await this.$myaxios.get('/get_registration_status', {
                    headers:{"Authorization": this.$cognito.idToken},
                });
            }catch(err){ throw {'err':err}; }
            
            if(response.data.registration_status === REGISTERED_IN_DYNAMODB){
                regitrationStatus.registState = true;
                
                this.$store.commit('setRegistState', REGISTERED_IN_DYNAMODB);
                    if(response.data.compe_registration_status == COMPE_REGISTERED){
                        this.$store.commit('setCompeRegistState', COMPE_REGISTERED);
                        regitrationStatus.compeRegistState = true;
                    }else if(response.data.compe_registration_status == NOT_COMPE_REGISTERED){
                        this.$store.commit('setCompeRegistState', NOT_COMPE_REGISTERED);
                        regitrationStatus.compeRegistState = false;
                    }
                    
                    return regitrationStatus;
            }else if(response.data.registration_status === NOT_REGISTERED_IN_DYNAMODB){
                regitrationStatus.registState = false;
                regitrationStatus.compeRegistState = false;
                
                this.$store.commit('setRegistState', NOT_REGISTERED_IN_DYNAMODB);
                this.$store.commit('setCompeRegistState', NOT_COMPE_REGISTERED);
                
                return regitrationStatus;
            }else{
                throw 'INVALID_LAMBDA_RESPONSE';
            }
        },
        // ログアウトする。
        // また、期限切れによる自動ログアウトが発生したかを戻り値で示す。
        signOut: async function(){
            // 現在のログイン状態を確認。
            //   ※セッションを発行できるならログイン済みとみなす。
            //   ※amazon-cognito-identity-jsは、a
            //   authenticateUser()でログインしていなくとも
            //   getSession()でセッション発行できることがあるため、
            //   ログイン後にvuexに格納しているuuidを確認することで、
            //   ログインをした後かどうかを家訓にできる。
            var session = null;
            if(this.$store.state.userData.uuid){
                try{
                    session = await this.$cognito.isAuthenticated();
                }catch(err){
                    session = null;
                }
            }
            var livingSigninSessionExists = !!session;
            
            // 現在のログイン状態はともかく、当関数実行前にログインしていたかを確認
            var isSignined;
            if(this.$store.state.userData.uuid){
                isSignined = true;
            }else{
                isSignined = false;
            }
            
            // 「ログインしていたがセッション期限切れとなったか？」を判定する。
            var signinSessionExpirationOccurred = false;
            if(!livingSigninSessionExists && isSignined){
                signinSessionExpirationOccurred = true;
            }
            
            try{
                await this.$cognito.globalSignOut();
            }catch(err){
                //this.dispErrorMessage('ログアウトに失敗しました。', err);
                console.log('ログアウトに失敗しました。', err);
            }
            
            this.$store.commit("deleteAllUserData");
            return {
                nextPath: '/golf/signin',
                signinSessionExpirationOccurred: signinSessionExpirationOccurred,
            };
        },
        // リフレッシュトークンが期限切れなら、
        // ログアウトして自動ログアウトしたことを画面で通知してログイン画面に遷移する。
        signOutIfRefreshTokenExpired: async function(){
            // ログイン状態を確認。
            //   ※セッションを発行できるならログイン済みとみなす。
            //   ※amazon-cognito-identity-jsは、a
            //   authenticateUser()でログインしていなくとも
            //   getSession()でセッション発行できることがあるため、
            //   ログイン後にvuexに格納しているuuidを確認することで、
            //   ログインをした後かどうかを家訓にできる。
            var session = null;
            if(this.$store.state.userData.uuid){
                try{
                    session = await this.$cognito.isAuthenticated();
                }catch(err){
                    session = null;
                }
            }
            
            // ログイン済みの場合
            if(session){
                return false;
            // 未ログインの場合
            }else{
                var signoutObj = await this.signOut();
                // 自動ログアウトの場合、メッセージコンポーネントをログイン画面に表示する
                if(signoutObj.signinSessionExpirationOccurred){
                    const msg = '一定時間経過したため、自動ログアウトしました。再度ログインをお願いします。';
                    this.dispNotifMessageAfterPageTransition(msg,'warning', '/golf/signin'); 
                }
                this.$router.push(signoutObj.nextPath);
                return true;
            }
        },
        // "undefinedによるTypeErrorを起こさずに"あるプロパティが存在するかを判断する。
        //   obj.type.codeが存在するかを確認したい場合、
        //   propExists([()=>obj, ()=>obj.type, ()=>obj.type.code]) とする。
        propExists: function(callbackList){
            // オブジェクトプロパティへのアクセスをコールバック実行により試行する。
            try{
                callbackList.forEach(callback=>callback());
            }catch(err){
                return false;
            }
            return true;
        },
        // Cognitoから発生したエラーかどうかを"undefinedによるTypeErrorを起こさずに"判断する。
        //   error.codeがあればtrue
        //   なければ'TypeError: Cannot read properties of undefined'
        //   を防ぎつつfalseを返す。
        isCognitoError: function(error){
            return this.propExists([()=>error, ()=>error.code]);
        },
        // ApiGatewayから発生したエラーかどうかを"undefinedによるTypeErrorを起こさずに"判断する。
        //   error.data.codeとerror.data.messageがあればtrue
        //   なければ'TypeError: Cannot read properties of undefined'
        //   を防ぎつつfalseを返す。
        isApiGatewayError: function(error){
            return this.propExists([
                ()=>error,
                ()=>error.data,
                ()=>error.data.code,
                ()=>error.data.message,
            ]);
        },
        // FIXME
        //   これはvuexのgettersとして定義したいが、
        //   そうするとundefinedになって使えなくなる。
        errorMsgExists: function(){
            return this.propExists([
                ()=>this.$store.state.errorMsg,
                ()=>this.$store.state.errorMsg.errorObj,
                ()=>this.$store.state.errorMsg.message,
            ]);
        },
    },
};