import Vue from 'vue';
import App from './App.vue';
import store from './store/index.js';
import router from './router/index.js';
import vuetify from './plugins/vuetify';
import myaxios from './services/myaxios.js';
import cognito from './services/cognito.js';
import common from './services/common.js';

Vue.config.productionTip = false
Vue.prototype.$myaxios = myaxios;
Vue.prototype.$cognito = new cognito();
Vue.prototype.$cognito.initUserPool(
    process.env.VUE_APP_COGNITO_ID,
    process.env.VUE_APP_COGNITO_CLIENT_ID);
Vue.mixin(common);

var vue = new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');

router.beforeEach(async (to, from, next) => {
    console.log('MYDEBUG: router.beforeEach:', 'start beforeEach()');

    // 画面遷移時にエラーメッセージをクリア
    vue.$store.commit('setNotifMsg', '');
    vue.$store.commit('setErrorMsg', '');

    // ログイン状態を確認。
    //   ※セッションを発行できるならログイン済みとみなす。
    //   ※amazon-cognito-identity-jsは、
    //   authenticateUser()でログインしていなくとも
    //   getSession()でセッション発行できることがあるため、
    //   ログイン後にvuexに格納しているuuidを確認することで、
    //   ログインをした後かどうかを家訓にできる。
    var session = null;
    // 登録状況を取得
    if (vue.$store.state.userData.uuid) {
        try {
            session = await vue.$cognito.isAuthenticated();
        }
        catch (err) {
            session = null;
        }
    }

    var gotoPath = null;

    // ログインが必要なページへ遷移する場合
    if (to.matched.some(record => record.meta.requiredSignIn)) {
        console.log('MYDEBUG: router.beforeEach:', 'requiredSignIn');
        gotoPath = await requiredSignInCheck(to, session);
        // ログインしていないことを要するページへ遷移する場合
    }
    else if (to.matched.some(record => record.meta.requiredNotSignIn)) {
        console.log('MYDEBUG: router.beforeEach:', 'requiredNotSignIn');
        // ログイン済みの場合
        if (session) {
            console.log('MYDEBUG: router.beforeEach:', '強制mypage');
            gotoPath = '/golf/mypage';
        }
        // 特別なルート制御の必要ないページへ遷移する場合
    }

    // 遷移を行う
    if (gotoPath) {
        console.log('MYDEBUG: router.beforeEach:', 'go', gotoPath);
        if (to.path != gotoPath) {
            next({ path: gotoPath });
        }
    }

    next();
});


///////////////////////////////////
// ルーティング制御関数
///////////////////////////////////
// ログインが必要なページへ遷移する場合のチェック
// チェックにパスしない場合は強制的にリダイレクトすべきパスを文字列で返す
async function requiredSignInCheck(to, session) {
    // ログイン済みの場合
    if (session) {

        let registration;
        try {
            registration = await vue.isRegisteredInDynamodb();
        }
        catch (err) {
            return '/error';
        }

        const registrationStatus = registration.registState;
        const compeRegistState = registration.compeRegistState;

        if (!registration.registState) {
            return '/golf/userRegistrationInputPage';
        }

        if (registrationStatus && !compeRegistState) {
            return '/golf/userRegistrationInputPage';
        }

        // 「個人情報をDynamoDBに登録済み」を要する場合 
        if (to.matched.some(record => record.meta.requiredDynamo)) {
            // 個人情報をDynamoDBに登録済みの場合
            if (registrationStatus) {
                //PT3
                console.log('MYDEBUG: requredSignInCheck: inDynamo registed True');
                return null;
                // 個人情報をDynamoDBに登録済みでない場合
            }
            else {
                console.log('MYDEBUG: requredSignInCheck: inDynamo registed False');
                return '/golf/userRegistrationInputPage';
            }
            // 「個人情報をDynamoDBに登録済み」ではならない場合 
        }
        else if (to.matched.some(record => record.meta.requiredNotDynamo)) {
            // 個人情報がDynamoDBに未登録の場合
            if (registrationStatus == false) {
                console.log('MYDEBUG: requredSignInCheck: notDynamo registed False');
                return null;
                // 個人情報をDynamoDBに登録済みの場合
            }
            else if (registrationStatus == true) {
                console.log('MYDEBUG: requredSignInCheck: notDynamo registed True');
                return '/golf/mypage';
            }
        }
        // ログイン済みでない場合やセッション期限切れの場合
    }
    else {
        // 状態が悪さをしないよう、状態リセットのため、ダメ押しでログアウトする。
        var signoutObj = await vue.signOut();

        // 期限切れによる自動ログアウトが発生していた場合、自動ログアウトしたことを画面に表示する
        if (signoutObj.signinSessionExpirationOccurred) {
            const msg = '一定時間経過したため、自動ログアウトしました。再度ログインをお願いします。';
            vue.dispNotifMessageAfterPageTransition(msg, 'warning', '/golf/signin');
        }
        return signoutObj.nextPath;
    }
}
